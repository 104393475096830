import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// modulo de firebase y modulo para firestore
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { AngularFireStorageModule } from '@angular/fire/storage';

// custom components
import { PublicationsListComponent } from './components/publications/publications-list/publications-list.component';
import { AddPublicationComponent } from './components/publications/add-publication/add-publication.component';
import { HomeSearchComponent } from './components/home-search/home-search.component';
import { CardPublicationComponent } from './components/publications/card-publication/card-publication.component';
import { SearchPipe } from './pipes/search';
import { RemoveCharacterPipe } from './pipes/removeCharacter';
import { PublicationDetailComponent } from './components/publications/publication-detail/publication-detail.component';
import { SuscribeComponent } from './components/users/suscribe/suscribe.component';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';

@NgModule({
  declarations: [
    AppComponent,
    PublicationsListComponent,
    AddPublicationComponent,
    HomeSearchComponent,
    CardPublicationComponent,
    SearchPipe,
    RemoveCharacterPipe,
    PublicationDetailComponent,
    SuscribeComponent,
    LoadingSpinnerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    FormsModule,
    AngularFireStorageModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
