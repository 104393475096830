// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCNpUYkqBikeIGu4bPDxCAbApyrjMdiwFg",
    authDomain: "buscadorsanmiguel.firebaseapp.com",
    databaseURL: "https://buscadorsanmiguel.firebaseio.com",
    projectId: "buscadorsanmiguel",
    storageBucket: "buscadorsanmiguel.appspot.com",
    messagingSenderId: "255814456773",
    appId: "1:255814456773:web:0b4018f2e3d5cb081767ac",
    measurementId: "G-KG28FX61VF"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
