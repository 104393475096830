import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  public transform(value, delivery: boolean, args: string) {
    if (!value) {
      return;
    }
    if (!args && !delivery) {
      return value;
    }

    return value.filter( (item) => {
      if (!args && delivery)
        return item.delivery;
      
      //busca por title o palabras clave. Es mas preciso que haciendo el stringify
      args = args.toLowerCase();
      console.log(delivery);
      if(delivery)
        return (item.title.toLowerCase().includes(args.toLowerCase()) || item.keyWords.toLowerCase().includes(args.toLowerCase())) && item.delivery;
      else
        return (item.title.toLowerCase().includes(args.toLowerCase()) || item.keyWords.toLowerCase().includes(args.toLowerCase()));

      // return (item.title.toLowerCase().includes(args.toLowerCase()) || item.keyWords.toLowerCase().includes(args.toLowerCase())) && item.delivery;

      // return JSON.stringify(item).toLowerCase().includes(args.toLowerCase());
    });
  }

  // public transform(items : any[], searchText: string):any[] {
  //   if (!items) {
  //     return [];
  //   }
  //   if (!searchText) {
  //     return items;
  //   }
  //   searchText = searchText.toLowerCase();

  //   return items.filter(it => {
  //     console.log(it);
  //     return it.Title.toLocaleLowerCase().includes(searchText);
  //   });
  // }

}