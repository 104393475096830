export interface Publication {
    // $key?: string;
    key: string;
    title: string;
    resumen: string;
    description: string;
    category: Categories;
    imageUrl: string;
    imageId?: string;
    keyWords: string;
    delivery: boolean;
    fechaVencimiento: Date;

    whapp: string;
    telefono: string;
    facebook: string;
    instagram: string;
    email: string;
    web: string;

    direccion: string;
    horario: string;
    latitud: string;
    longitud: string;
  
    // listar: boolean; // para determinar si se muestra en los listados públicos o no.
    // telefono: string;
    // webUrl: string;
    // facebook: string;
    // instagram: string;
  
  }
  
  export enum Categories {
    Herramientas = 1,
    Plomeria = 2,
    Electronica = 3,
    Comida = 4,
    Servicios = 5,
    Comercio = 6
  }
  