import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { PublicationsService } from 'src/app/services/publications.service';
import { Observable } from 'rxjs';
import { Publication } from 'src/app/interfaces/publication';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-home-search',
  templateUrl: './home-search.component.html',
  styleUrls: ['./home-search.component.css']
})
export class HomeSearchComponent implements OnInit {

  publications: any;
  // query: string = '';
  delivery: boolean;
  first: any;
  last: any;
  showSpinner : boolean = true;
 
  constructor(private publicationService: PublicationsService) { }
 
  ngOnInit() {
    this.getPublicationsList();
    this.delivery = false;
  }
 
  getPublicationsList() {
    // this.publicationService.getPublicationsList().valueChanges()
    // .subscribe(publications => {
    //   this.publications = publications;
    //   console.log(this.publications);
    // });

    // 20200726: antes tenia esta consulta con snapshot y tenia que hacer el pipe y el map para obtener los datos... no se por que. 
    // ahora tengo el valueChanges solo. Vi que la diferencia es que uno trae la metadata del documento y el otro solo los datos...
    // en fin... ademas es mas corto. A no ser que no sea necesario hacer todo el pipe y map con el snapshot?...
    // bueno, hice la prueba. Con snapshotChanges tengo que hacer el map si o si, supongo porque trae el doc completo y entonces
    // tengo que extraer la data...
    this.publicationService.getPublicationsList().snapshotChanges().pipe(
        map(changes =>
          changes.map(c =>
            ({ key: c.payload.doc.id, ...c.payload.doc.data() })
          )
        )
      )
      .subscribe(publications => {
        this.publications = publications;
        this.first = publications[0];
        this.last = publications[publications.length - 1];
        this.showSpinner = false;
      });

  }

  // nextList(){
  //   this.publicationService.getPublicationsListNext(this.last).snapshotChanges().pipe(
  //     map(changes =>
  //       changes.map(c =>
  //         ({ key: c.payload.doc.id, ...c.payload.doc.data() })
  //       )
  //     )
  //   )
  //   .subscribe(publications => {
  //     this.publications = publications;
  //     this.first = publications[0];
  //     this.last = publications[publications.length - 1];

  //   });
  // }

}
