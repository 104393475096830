import { Component, OnInit } from '@angular/core';
import { PublicationsService } from 'src/app/services/publications.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-publications-list',
  templateUrl: './publications-list.component.html',
  styleUrls: ['./publications-list.component.css']
})
export class PublicationsListComponent implements OnInit {

  publications: any;
 
  constructor(private publicationService: PublicationsService) { }
 
  ngOnInit() {
    this.getPublicationsList();
  }
 
  getPublicationsList() {
    this.publicationService.getPublicationsAllList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(publications => {
      this.publications = publications;
    });
  }

}
