import { Component, OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { PublicationsService } from 'src/app/services/publications.service';
import {Publication, Categories} from '../../../interfaces/publication'
import { Router } from '@angular/router';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-publication',
  templateUrl: './add-publication.component.html',
  styleUrls: ['./add-publication.component.css']
})
export class AddPublicationComponent implements OnInit {

  publication: Publication;
  public categories = Object.keys(Categories)
    .slice(Object.keys(Categories).length / 2)
    .map(key => ({ label: key, key: Categories[key] }));

    private image: any;
    public imageSrc;
    fechaVencimiento: NgbDateStruct;
    
  constructor(private publicationService: PublicationsService,
    private router: Router) { }

  ngOnInit() {
  }

  onSubmit(p: NgForm){
    p.value.fechaVencimiento = new Date(p.value.dp.year, p.value.dp.month, p.value.dp.day);
    delete p.value['dp'];
    this.publicationService.createPublication(p.value, this.image);
    this.router.navigate(['/thisisthelistofpublicationwithoutanyfilter']);
  }

  // Para mostrar la imagen en el formulario.
  onFileChange(event) {
    if (event.target.files.length > 0) {
      // esto guarda el fichero para subir a FireStore
      this.image = event.target.files[0];
      // this.form.get('avatar').setValue(file);
      // console.log(this.image);
      // esto es para mostrar el preview en el modal
      const reader = new FileReader();
      this.imageSrc = event.target.files;
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = e => {
        this.imageSrc = reader.result;
      };
    }
  }

}
