import { Component, OnInit, Input } from '@angular/core';
import { Publication } from 'src/app/interfaces/publication';
import { Router, ActivatedRoute } from '@angular/router';
import { PublicationsService } from 'src/app/services/publications.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-publication-detail',
  templateUrl: './publication-detail.component.html',
  styleUrls: ['./publication-detail.component.css']
})
export class PublicationDetailComponent implements OnInit {
  publication: Publication;
  
  constructor(private route: ActivatedRoute, private service: PublicationsService) { }

  ngOnInit() {
    // aca uso el snapshot del parametro y no me servia al navegar desde el mismo component
    // const publi: Observable<Publication> = this.service.getPublicationsById(this.route.snapshot.paramMap.get('id')).valueChanges()
    // publi.subscribe(
    //   data => this.publication = data
    // );

    // aca me suscribo al observable de los parametros. Ahi cada vez que cambia, me lo actualiza. CHETO.
    this.route.params.subscribe(params => {
      let pId: string = params['id'];
      this.service.getPublicationsById(pId).valueChanges().subscribe(data => {
        if (data != null) {
          this.publication = data;
        }
      });
    });
  }

}
