import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Publication } from '../interfaces/publication';
import { AngularFireStorage } from '@angular/fire/storage';
import { timestamp } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PublicationsService {

  private dbPath = '/publications';
  publicationsRef: AngularFirestoreCollection<Publication> = null;

  constructor(private db: AngularFirestore, private firebaseStorage: AngularFireStorage) {
    // this.publicationsRef = db.collection(this.dbPath, ref => ref.limit(4));
  }

  getPublicationsList(): AngularFirestoreCollection<Publication> {
    let today = new Date(Date.now());
    // .limit(10)
    // .limit(9).orderBy('title', 'desc'));
    // this.publicationsRef = this.db.collection(this.dbPath, ref => ref.where('fechaVencimiento', '>', today));
    this.publicationsRef = this.db.collection(this.dbPath);
    return this.publicationsRef;
  }

  getPublicationsAllList(): AngularFirestoreCollection<Publication> {
    let today = new Date(Date.now());
    this.publicationsRef = this.db.collection(this.dbPath);
    return this.publicationsRef;
  }

  // metodo nuevo para el tema del NEXT y PREV
  // getPublicationsListNext(startAt): AngularFirestoreCollection<Publication> {
  //   console.log(startAt);
  //   this.publicationsRef = this.db.collection(this.dbPath, ref => ref
  //     .limit(9)
  //     .orderBy('title', 'desc')
  //     .startAfter(startAt.title)
  //     //.endBefore(firstInResponse)
  //     );
  //   return this.publicationsRef;
  // }

  getPublicationsById(key: string): AngularFirestoreDocument<Publication> {
    if(this.publicationsRef == null){
      this.publicationsRef = this.db.collection(this.dbPath, ref => ref.limit(10));
    }
    return this.publicationsRef.doc(key);
  }

  createPublication(publication: Publication, image: any): void {
    
    const imageId = Date.now();
    const images = this.firebaseStorage.ref('images/' + imageId + '.jpg').put(image);
    // images devuelve una promise, se maneja a continuacion
    images
    .then(result => {
      const imageURL = this.firebaseStorage.ref('images/' + imageId + '.jpg').getDownloadURL();
      // imageURL tambien devuelve una promise
      imageURL.subscribe(p => {
        publication.imageUrl = p;
        publication.imageId = imageId + '.jpg';
        console.log('subió la imagen: ' + p);
        //esta es la linea que agrega (de la nueva forma)
        this.publicationsRef.add({...publication});
          //return this.publicationsList.push(publication);
        });
        // .catch(error => {
        //   console.log('no subio: ' + error);
        // });
      })
      .catch(error => {
        console.log(error);
      });
  }

  updatePublication(key: string, value: any): Promise<void> {
    return this.publicationsRef.doc(key).update(value);
  }
 
  deletePublication(key: string): Promise<void> {
    return this.publicationsRef.doc(key).delete();
  }
 
  // deleteAll() {
  //   this.publicationsRef.get().subscribe(
  //     querySnapshot => {
  //       querySnapshot.forEach((doc) => {
  //         doc.ref.delete();
  //       });
  //     },
  //     error => {
  //       console.log('Error: ', error);
  //     });
  // }
}
