import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicationsListComponent } from './components/publications/publications-list/publications-list.component';
import { AddPublicationComponent } from './components/publications/add-publication/add-publication.component';
import { HomeSearchComponent } from './components/home-search/home-search.component';
import { PublicationDetailComponent } from './components/publications/publication-detail/publication-detail.component';
import { SuscribeComponent } from './components/users/suscribe/suscribe.component';


const routes: Routes = [
  {
    path: '',
    component: HomeSearchComponent
  },
  {
    path: 'thisisthelistofpublicationwithoutanyfilter',
    component: PublicationsListComponent
  },
  {
    path: 'wearegoingtoaddsomenewpublicationstothelist',
    component: AddPublicationComponent
  },
  {
    path: 'pd/:id',
    component: PublicationDetailComponent
  },
  {
    path: 'suscribe',
    component: SuscribeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
