import { Component, OnInit, Input } from '@angular/core';
import { Publication } from 'src/app/interfaces/publication';

@Component({
  selector: 'app-card-publication',
  templateUrl: './card-publication.component.html',
  styleUrls: ['./card-publication.component.css']
})
export class CardPublicationComponent implements OnInit {

  @Input() publication: Publication;
  showSpinner: boolean = true;
  
  constructor() { }

  ngOnInit() {

  }
  hideSpinner(){
    this.showSpinner = false;
  }

}
